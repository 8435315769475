
<template>
  <div>
    <div class="animate-me name">HATİCE Akpınar</div>
    <div class="animate-me desc">Software Developer</div>
    <div class="container">
      <div class="switch-background off-shadow">
        <div class="innerSwitch">
          <svg
            width="285"
            height="150"
            viewBox="0 0 208 31"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            class="face"
          >
            <path
              class="mouth"
              d="M77 30H130V30.3375C130 30.7034 118.136 31 103.5 31C88.8645 31 77 30.7034 77 30.3375V30Z"
              fill="#E76060"
            />
            <path
              class="eye eye-left"
              d="M60 0.000190258C60 16.5687 46.5685 30.0002 30 30.0002C13.4315 30.0002 0 16.5687 0 0.000190258C0 0.000190258 13.4315 5.5 30 5.5C46.5685 5.5 60 0.000190258 60 0.000190258Z"
              fill="#474747"
            />
            <path
              class="eye eye-right"
              d="M208 0.999969C208 17.5685 194.569 31 178 31C161.431 31 148 17.5685 148 0.999969C148 0.999969 161.431 9.00001 178 9.00001C194.569 9.00001 208 0.999969 208 0.999969Z"
              fill="#474747"
            />
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import anime from "animejs/lib/anime.es.js";
import Letterize from "letterizejs";

export default {
  name: "HomePage",
  mounted() {
    const test = new Letterize({
      targets: document.getElementsByClassName("animate-me"),
    });

    const animation = anime.timeline({
      targets: test.listAll,
      delay: anime.stagger(100, {
        grid: [test.list[0].length, test.list.length],
        from: "center",
      }),
      loop: false,
    });

    animation
      .add({
        scale: 1,
      })
      .add({
        scale: 1,
      })
      .add({
        letterSpacing: "10px",
      });

    const descLet = new Letterize({
      targets: document.getElementsByClassName("desc"),
    });

    const animationDesc = anime.timeline({
      targets: descLet.listAll,
      delay: anime.stagger(100, {
        from: "center",
      }),
      loop: false,
    });

    animationDesc.add({ scale: 1 }).add({ translateY: "18" });

    const eyeOpenL =
      "M60 30C60 46.5685 46.5685 60 30 60C13.4315 60 0 46.5685 0 30C0 13.4315 13.4315 0 30 0C46.5685 0 60 13.4315 60 30Z";
    const eyeOpenR =
      "M208 31C208 47.5685 194.569 61 178 61C161.431 61 148 47.5685 148 31C148 14.4315 161.431 1 178 1C194.569 1 208 14.4315 208 31Z";
    const eyeClosedL =
      "M60 0.000190258C60 16.5687 46.5685 30.0002 30 30.0002C13.4315 30.0002 0 16.5687 0 0.000190258C0 0.000190258 13.4315 5.5 30 5.5C46.5685 5.5 60 0.000190258 60 0.000190258Z";
    const eyeClosedR =
      "M208 0.999969C208 17.5685 194.569 31 178 31C161.431 31 148 17.5685 148 0.999969C148 0.999969 161.431 9.00001 178 9.00001C194.569 9.00001 208 0.999969 208 0.999969Z";
    const mouthOpen =
      "M77 60H130V73.5C130 88.1355 118.136 100 103.5 100C88.8645 100 77 88.1355 77 73.5V60Z";
    const mouthClosed =
      "M77 30H130V30.3375C130 30.7034 118.136 31 103.5 31C88.8645 31 77 30.7034 77 30.3375V30Z";
    const face = document.querySelector(".innerSwitch");
    const switchBG = document.querySelector(".switch-background");
    const background = document.querySelector("body");
    let switched = false;

    face.addEventListener("click", () => {
      var tl = anime.timeline({
        easing: "easeOutExpo",
        duration: 800,
      });
      tl.add(
        {
          targets: [face],
          translateX: switched ? -1 : 32,
          rotate: switched ? -90 : 90,
          backgroundColor: switched ? "rgb(110,240,225)" : "rgb(243,255,148)",
        },
        500
      )
        .add(
          {
            targets: [switchBG, background],
            backgroundColor: switched ? "rgb(0,0,0)" : "rgb(255, 135, 48)",
          },
          500
        )
        .add(
          {
            targets: ".eye-left",

            d: [
              {
                value: switched ? eyeClosedL : eyeOpenL,
              },
            ],
          },
          "-=1200"
        )
        .add(
          {
            targets: ".eye-right",
            d: [
              {
                value: switched ? eyeClosedR : eyeOpenR,
              },
            ],
          },
          "-=1210"
        )
        .add(
          {
            targets: ".mouth",
            d: [
              {
                value: switched ? mouthClosed : mouthOpen,
              },
            ],
          },
          "-=1210"
        );

      if (switched == true) {
        //if true
        if (switchBG.classList.contains("on-shadow")) {
          switchBG.classList.remove("on-shadow");
        }
        switchBG.classList.add("off-shadow");
        switched = false;
      } else {
        if (switchBG.classList.contains("off-shadow")) {
          switchBG.classList.remove("off-shadow");
        }
        switchBG.classList.add("on-shadow");
        switched = true;
      }
    });
  },
};
</script>

<style>
body {
  background-color: rgb(0, 0, 0);
  color: #fff;
  font-weight: 400;
  font-size: 2vw;
  display: flex;
  text-transform: uppercase;
  height: 100vh;
  box-sizing: border-box;
  letter-spacing: 6px;
  margin: 0;
  line-height: 1.2;
}

span {
  display: inline-block;
}

.animate-me {
  margin-left: 20px;
}

.name {
  font-size: 4.46vw;
  margin-top: 33vh;
}

.desc {
  font-size: 1.67vw;
  margin-top: -1.9vw;
  opacity: 0.35;
}

.container {
  display: flex;
  position: fixed;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  bottom: 30px;
  right: 30px;
  /* background-color: black; */
}

.switch-background {
  background-color: rgb(49, 49, 49);
  height: 25px;
  width: 56px;
  border-radius: 25px;
  margin-top: 3em;
  /* padding-left: 15px;
  padding-top: 5px; */
  /* box-shadow: 12px 12px 22px gray, inset 12px 12px 10px #402955; */
  box-shadow: 1px 1px 5px rgb(43, 43, 43),
    -1px -1px 5px rgba(202, 191, 209, 0.636);
  transition: box-shadow 1s ease-in;
}
.off-shadow {
  box-shadow: 1px 1px 5px rgb(56, 32, 72),
    -1px - 1px 5px rgba(211, 198, 219, 0.636);
}
.on-shadow {
  /* visibility: hidden; */
  box-shadow: 1px 1px 5px rgb(99, 17, 46),
    -1px -1px 5px rgba(161, 143, 156, 0.468);
  /* box-shadow: 12px 12px 22px gray, inset 12px 12px 10px #402955; */
}
.innerSwitch {
  /* margin-left: 20px;
  margin-right: 20px; */
  background-color: #65d3ca;
  width: 25px;
  height: 25px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.face {
  width: 15px;
  padding-bottom: 3px;
}

@media only screen and (max-width: 450px) {
  .name {
    font-size: 6.9vw;
  }

  .desc {
    font-size: 3.67vw;
    margin-top: -3.67vw;
    opacity: 0.4;
  }
}
</style>>

